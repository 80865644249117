import React, { useEffect } from "react";

import CohortMenu from "./cohort-menu/CohortMenu";
import CohortPartners from "./cohort-partners/CohortPartners";
import CohortEnroll from "./cohort-enroll/CohortEnroll";

import { scrollToSignup } from "../../utils";
import { STORAGE_KEY } from "../../constants";

import "./cohort-comp.scss";
import NewFooter from "../reusables/new-footer/NewFooter";

function CohortComp() {
  useEffect(() => {
    if (global.window.localStorage.getItem(STORAGE_KEY)) {
      setTimeout(() => scrollToSignup(), 500);
      global.window.localStorage.clear(STORAGE_KEY);
    }
  }, []);

  return (
    <section className="slide whiteSlide kenBurns cohort-comp">
      <div className="cohort-comp__hero">
        <h6 className="ae-1">COHORT 1</h6>
        <h1 className="ae-2 largest">
          From zero to{" "}
          <span>
            pro <img className="hero-crown" src="/assets/img/crown.svg" />
          </span>{" "}
        </h1>
        <p className="ae-3 largest">
          Launch your career in tech as a <strong>product designer</strong> with
          our certified 3-month design coaching program.
        </p>
      </div>

      <CohortMenu />
      <CohortPartners />
      <CohortEnroll />
      <NewFooter />
    </section>
  );
}

export default CohortComp;
