import React from "react";
import "./who-is-it-for.scss";
import { scrollToSignup } from "../../../../utils";

const whoIsItFor = [
  "Career Switchers – Ready to transition into product design? Gain the skills, knowledge, and portfolio to make the switch confidently.",
  "Aspiring Designers – Want to start a career in UI/UX but don’t know where to begin? Learn the fundamentals, work on real projects, and build your portfolio.",
  "Beginners with No Prior Experience – No design background? No problem. We’ll guide you step by step, from the basics to professional-level product design skills.",
];

function WhoIsItFor() {
  return (
    <>
      <div className="body-content who-is-it-for experience">
        <h3>No prior experience? No problem.</h3>
        <p>
          This program is designed for aspiring and transitioning designers who
          want to master product design, create impactful digital experiences,
          and build a career in tech. If you’re eager to learn, create, and
          grow, this is for you!
        </p>
      </div>

      <div className="body-content who-is-it-for start">
        <ul>
          {whoIsItFor.map(item => (
            <li key={item}>✅ {item}</li>
          ))}
        </ul>
        <p>
          No experience? No worries. This program is designed to take you from
          zero to job-ready in just three months.
        </p>
        <button onClick={scrollToSignup}>Start today.</button>
      </div>

      <div className="body-content who-is-it-for social-media">
        <div className="social-media-img">
          <img src="/assets/img/burst-social-media.png" />
        </div>
      </div>
    </>
  );
}

export default WhoIsItFor;
