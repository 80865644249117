import React, { useState } from "react";
import ProgramOverview from "./program-overview/ProgramOverview";
import WhoIsItFor from "./who-is-it-for/WhoIsItFor";
import WhatYouLearn from "./what-you-learn/WhatYouLearn";
import TuitionFee from "./tuition-fee/TuitionFee";

const menuList = [
  "Program overview",
  "Who is it for?",
  "What you'll learn",
  "Tuition fee",
];

function CohortMenu() {
  const [tab, setTab] = useState("Program overview");

  return (
    <div className="cohort-comp__content ae-4">
      <menu>
        {menuList.map(item => (
          <li
            onClick={() => setTab(item)}
            className={tab === item ? "active" : ""}
            key={item}
          >
            {item}
          </li>
        ))}
      </menu>

      <div className="cohort-comp__content-body" key={tab}>
        {tab === "Program overview" && <ProgramOverview />}
        {tab === "Who is it for?" && <WhoIsItFor />}
        {tab === "What you'll learn" && <WhatYouLearn />}
        {tab === "Tuition fee" && <TuitionFee />}
      </div>
    </div>
  );
}

export default CohortMenu;
