import React from "react";

import "./new-footer.scss";

export default function NewFooter() {
  const year = new Date().getFullYear();

  return (
    <div className="footer">
      <span><a href="https://docs.google.com/document/d/1zAtRZc5iTDWWTYDDXgypep4ta0FhMEG1NSZLraTI3xo/edit?usp=sharing" target="_blank">Terms</a></span>
      <span>&#169; {year} The Unflux, <a href="https://dandeworld.com" target="_blank">a Dandeworld Project</a></span>
    </div>
  );
}
