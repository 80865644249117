import React, { useEffect } from "react";
import axios from "axios";
import { validateEmail } from "../../../utils/validations";

import "./cohort-enroll.scss";

const field = { name: "", email: "" };
const errorField = { name: "", email: "", api: "" };
const emailErrorTxt = "Please enter a valid email address";
const nameErrorTxt = "Please remove numbers from the name";

function CohortEnroll() {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(errorField);
  const [loading, setLoading] = React.useState(false);

  const [fields, setFields] = React.useState(field);

  const disableSubmit =
    Object.values(fields).some(v => !v) ||
    Object.values({ ...error, api: "" }).some(v => v);

  const enrollFn = async e => {
    e.preventDefault();
    if (!validateEmail(fields.email) || !fields.email) {
      setError(prev => ({ ...prev, email: emailErrorTxt }));
      return;
    }

    if (/\d/.test(fields.name) || !fields.name) {
      setError(prev => ({ ...prev, name: nameErrorTxt }));
      return;
    }

    try {
      setLoading(true);
      await axios.post(`${process.env.GATSBY_API_URL}/register`, fields, {
        headers: { "Content-Type": "application/json" },
      });
      setFields(field);
      setSuccess(true);
      global.window.localStorage.setItem("enroll", "true");
    } catch (err) {
      setError(prev => ({
        ...prev,
        api: err.response?.data?.message || "An error occurred.",
      }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (global.window.localStorage.getItem("enroll")) setSuccess(true);
  }, []);

  useEffect(() => {
    setError(errorField);
  }, [fields.name, fields.email]);

  return (
    <div className="cohort-enroll ae-6">
      <h4>Ready to become a pro designer? Enroll today.</h4>
      <p>
        We’re excited to have you join this program. Reserve your slot and we’ll
        be in touch.
      </p>

      {success ? (
        <div className="enroll-success">
          <img className="hero-crown" src="/assets/img/success-check.svg" />
          <h4>Submitted!</h4>
          <p>
            Thank you for your interest in joining this cohort. We’ll reach out
            to you via email with more details about your registration.
          </p>
        </div>
      ) : (
        <form id="enroll-form" onSubmit={enrollFn}>
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              className={error.name ? "error" : ""}
              type="text"
              id="name"
              name="name"
              required
              value={fields.name}
              onChange={e => setFields({ ...fields, name: e.target.value })}
            />
            {error.name && <small>{error.name}</small>}
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              className={error.email ? "error" : ""}
              type="text"
              id="email"
              name="email"
              required
              value={fields.email}
              onChange={e => setFields({ ...fields, email: e.target.value })}
            />
            {error.email && <small>{error.email}</small>}
          </div>
          <div className="form-group">
            {error.api && <small>{error.api}</small>}
            <button disabled={disableSubmit} type="submit">
              {loading ? <img src="/assets/img/spinner.svg" /> : "Continue"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default CohortEnroll;
