import React from "react";
import { scrollToSignup } from "../../../../utils";

import "./program-overview.scss";

const whatToMaster = [
  "UI/UX Design Principles – Learn how to design intuitive, user-friendly interfaces.",
  "Figma & Prototyping – Build and refine high-fidelity designs.",
  "Usability Testing – Use tools like Lyssna to create data-driven designs.",
  "Professional Growth – Work on real-world projects to build a job-ready portfolio.",
];

function ProgramOverview() {
  return (
    <>
      <div className="body-content program-overview learn">
        <h3>Learn to design delightful products and elevate your career.</h3>
        <div className="learn-img">
          <img src="/assets/img/burst-lettering-ux.svg" />
        </div>
      </div>
      <div className="body-content program-overview training">
        <p>
          Join our intensive 3-month product design training program and gain
          the skills needed to create user-centered, impactful digital products.
          Designed for aspiring and transitioning designers, this program blends{" "}
          <strong>
            hands-on learning, structured coursework, and real-world projects
          </strong>{" "}
          to help you level up.
        </p>

        <div className="training-list__wrapper">
          <p>With two live sessions per week, you’ll master</p>

          <ul>
            {whatToMaster.map(item => (
              <li key={item}>✅ {item}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="body-content program-overview apply">
        <div className="apply-img">
          <img src="/assets/img/Instructor Image.png" />
        </div>
        <p>
          Taught by industry experts, supported by a strong learning community.
          Whether you’re launching your career or upskilling, this program will
          equip you with the tools and knowledge to thrive in product design.
        </p>

        <p>
          📌 Limited spots available!{" "}
          <button onClick={scrollToSignup}>Apply now</button> to start your
          design journey.
        </p>
      </div>
    </>
  );
}

export default ProgramOverview;
