import React from "react";
import "./tuition-fee.scss";
import { scrollToSignup } from "../../../../utils";

const programs = [
  "Weekly live training sessions with expert instructors.",
  "Access to premium design tools (Figma, Uxcel Teams, Lyssna).",
  "Hands-on projects & portfolio-building opportunities.",
  "1-on-1 mentorship & career guidance.",
  "Exclusive learning community on Slack",
];

function TuitionFee() {
  return (
    <>
      <div className="body-content tuition invest">
        <h3>
          Invest in your future with an affordable and flexible payment plan.
        </h3>
        <p>
          Our intensive training program is designed to give you the skills,
          mentorship, and hands-on experience needed to launch your career—all
          at a budget-friendly price.
        </p>
      </div>

      <div className="body-content tuition payment">
        <div className="apply-img">
          <img src="/assets/img/Instructor Image 2.png" />
        </div>

        <p>
          <strong>Program Fee:</strong> $320 USD per participant
        </p>
        <p>
          <strong>Flexible Payment Plan:</strong> Pay 30% upfront and the remaining 70% after the first five sessions. Eligibility required.
        </p>
      </div>

      <div className="body-content tuition programs">
        <p>
          <strong>What&apos;s included?</strong>
        </p>

        <ul>
          {programs.map(program => (
            <li key={program}>
              <img src="/assets/img/check-mark.svg" />
              <p>{program}</p>
            </li>
          ))}
        </ul>
        <p>
          📌 <strong>Limited spots available!</strong> Secure your place today
          and start your journey into product design.
        </p>

        <button onClick={scrollToSignup}>Apply Now.</button>
      </div>
    </>
  );
}

export default TuitionFee;
