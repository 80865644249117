import React from "react";
import "./what-you-learn.scss";
import { scrollToSignup } from "../../../../utils";

const whatToLearn = [
  "Product Design Fundamentals",
  "Prototyping",
  "Testing Your Designs",
  "Designer’s Role in Cross-Functional Teams",
  "Your Portfolio and Career Readiness",
];

function WhatYouLearn() {
  return (
    <>
      <div className="body-content what-you-learn experience">
        <h3>Expert-led learning to unlock your potential in product design.</h3>
        <div className="__img">
          <img src="/assets/img/Illustration.png" />
        </div>
      </div>

      <div className="body-content what-you-learn learning-themes">
        <p>
          Over the course of three months, you’ll gain the skills needed to
          design user-friendly, impactful digital products. We collaborate with
          <strong> skilled industry experts</strong> to create high-quality
          learning materials that will get you job-ready through{" "}
          <strong>
            hands-on training and projects solving real-world problems.
          </strong>
        </p>

        <div className="list__wrapper">
          <p>A sneak peek at our learning themes for this program:</p>

          <ul>
            {whatToLearn.map(item => (
              <li key={item}>
                <img src="/assets/img/star.svg" />
                <p>{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="body-content what-you-learn materials">
        <p>
          📌 With expert-led learning materials and practical training, you’ll
          finish the program ready to launch your career in product design.
        </p>

        <div className="download__wrapper">
          <a
            href="https://drive.usercontent.google.com/download?id=1yeRnknGtBfV15lHbwhj74sidtM-4iNoy&export=download&authuser=0"
            download
          >
            Download Complete Curriculum
          </a>
          <button onClick={scrollToSignup}>Start Learning.</button>
        </div>
      </div>
    </>
  );
}

export default WhatYouLearn;
