import React from "react";
import "./cohort-partners.scss";
import KoraLogo from "../../reusables/company-logos/KoraLogo";
import QuidaxLogo from "../../reusables/company-logos/QuidaxLogo";
// import PiggyvestLogo from "../../reusables/company-logos/PiggyvestLogo";
import FincraLogo from "../../reusables/company-logos/FincraLogo";
import DandeLogo from "../../reusables/company-logos/DandeLogo";

function CohortPartners() {
  return (
    <div className="cohort-partners ae-5">
      <h4>Our Coaches, Alumni and Partners work at top companies</h4>

      <ul>
        <li>
          <KoraLogo />
        </li>
        <li>
          <QuidaxLogo />
        </li>
        <li>
          <FincraLogo />
        </li>
        <li>
          <DandeLogo />
        </li>
      </ul>
    </div>
  );
}

export default CohortPartners;
