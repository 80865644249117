import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import CohortComp from "../components/cohort-comp/CohortComp";

const CohortPage = () => {
  return (
    <Layout>
      <SEO
        title={"Cohort"}
        description={"The meeting place for people, products, and great design"}
      />
      <CohortComp />
    </Layout>
  );
};

export default CohortPage;
